<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">Group Sale</h1>
            </div>
        </div>
        <div>
            <button type="button" class="btn btn-default float-left mb-3" @click="addEmployee"><i class="ion-plus"></i> Add</button>
        </div>
        <div>

            <table class="table table-hover table-bordered" id="datatable">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Updated By</th>
                        <th v-show="checkAcl('Edit')"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in data" :key="item.id">
                        <td>{{item.Id}}</td>
                        <td>{{item.Name}}</td>
                        <td>
                            <button type="button" class="btn btn-icon px-0"
                                    @click="toggleDisable(item.Id)"
                                    :class="{'btn-success':!item.IsDisable,'btn-danger':item.IsDisable}">
                                <i :class="{'ion-android-checkmark-circle':!item.IsDisable,'ion-android-cancel':item.IsDisable}"></i>
                            </button>
                        </td>
                        <td>{{item.UpdatedBy}}</td>
                        <td v-show="checkAcl('Edit')">
                            <button type="button" class="btn btn-default btn-sm" @click="ShowEdit(item.Id)" title="Edit">
                                <i class="ion-edit p-1"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>


        <MDBModal id="editModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showEditForm"
                  size="lg">
            <MDBModalHeader>
                <MDBModalTitle id="editModalTitle" v-html="editTitle"></MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>

                <MDBRow id="editForm" tag="form" class="g-3">

                    <MDBCol md="12">
                        <MDBInput label="Group Name *"
                                  id="Name"
                                  v-model="Name"
                                  invalidFeedback="Please provide a valid Name"
                                  validFeedback="Looks good!"
                                  title="NO special characters, the length should be between 2 and  50 characters" />
                    </MDBCol>


                </MDBRow>

            </MDBModalBody>
            <MDBModalFooter>
                <button type="submit" class="btn btn-success" @click="doSubmit">
                    {{editBtnText}}
                </button>
                <button type="button" class="btn btn-danger" @click="showEditForm=false">
                    Cancel
                </button>

            </MDBModalFooter>
        </MDBModal>


    </div>
</template>

<script>
    //https://therichpost.com/vue-3-datatable-with-export-buttons-print-csv-copy-with-dynamic-data/
    //Datatable Modules
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    import "datatables.net-buttons/js/dataTables.buttons.js"
    import "datatables.net-buttons/js/buttons.colVis.js"
    import "datatables.net-buttons/js/buttons.flash.js"
    import "datatables.net-buttons/js/buttons.html5.js"
    import "datatables.net-buttons/js/buttons.print.js"
    import 'datatables.net-responsive'

    import $ from 'jquery'

    //https://mdbootstrap.com/docs/b5/vue/components/modal/#docsTabsAPI
    import 'mdb-vue-ui-kit/css/mdb.min.css';
    import {
        //MDBBtn,
        MDBModal,
        MDBModalBody,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalFooter,
        MDBCol, MDBRow,
        MDBInput
    } from 'mdb-vue-ui-kit';


    import { mapState,mapActions } from 'vuex'
    export default {
        name: `GroupSale`,
        data: function () {
            return {
                LayoutModel: {},
                data: [],
                NameExisted: [],
                //=== edit popup====
                editId: 0,
                showEditForm: false,
                editTitle: 'Edit',
                editBtnText: 'Save',
                Name:'',
            }
        },
        components: {
            //MDBBtn,
            MDBModal,
            MDBModalHeader,
            MDBModalTitle,
            MDBModalBody,
            MDBModalFooter,
            MDBCol, MDBRow,
            MDBInput
        },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',
                
                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.getAll();                
            },
            addEmployee() {

                this.editId = 0;
                this.editTitle = "New Group Sale"
                this.editBtnText = "Add";
                this.Name = '';
                this.showEditForm = true;
                setTimeout(() => { this.validName()}, 100);

            },
            validName() {
                let isValid = true;
                let error = '';
                let name = this.Name.trim();
                let regex = /^([a-zA-Z0-9\s\-_.@]){0,}$/;
                if (!regex.test(name)) {
                    error = "Only accept @.-_";
                    isValid = false;
                }
                else if (name.length == 0) {
                    error = "Please input a Name!";
                    isValid = false;
                }
                //check length
                else if (name.length > 50) {
                    error = "Should not exceed 50 characters!";
                    isValid = false;
                }
                else //check duplicate name
                {
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].Name == name && (this.data[i].Id != this.editId || this.editId == 0))
                        {
                            error = "GroupName is existed!";
                            isValid = false;
                            break;
                        }
                    }                    
                }

                if (!isValid) {
                    $('#Name').addClass('is-invalid');
                    $('#Name').removeClass('is-valid');
                    $('#Name~.invalid-feedback')[0].innerHTML = error;
                } else {
                    $('#Name').removeClass('is-invalid');
                    $('#Name').addClass('is-valid');
                }

                return isValid;
            },
            async getAll() {
                this.loading(true);
                const rs = await this.call({ Action: 'GroupSale/List' })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    //no permission                    
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }
                } else {                    
                    this.data = rs.Data;
                    if (this.dataTable != null) this.dataTable.destroy();
                    setTimeout(this.doTable, 100);
                }
            },
            ShowEdit(itemid) {
                for (let i = 0; i < this.data.length; i++) {
                    const item = this.data[i];
                    if (item.Id == itemid) {
                        this.editTitle = "GroupSale <b>" + item.EmailId + "</b>";
                        this.editBtnText = "Save";
                        this.editId = itemid;
                        this.Name = item.Name;
                        this.showEditForm = true;
                        return;
                    }
                }
                this.showToast({ isShow: true, title: '', content: 'GroupSale not fount!', type: 'error' });
            },
            async doSubmit() {
                //======= validate =========
                //$('#editForm').addClass('was-validated');

                let isValid = true;
                isValid = isValid && this.validName();

                if (!isValid) {
                    this.showToast({ isShow: true, title: '', content: 'Form is invalidated.', type: 'error' });
                    return;
                }

                //======== do submit =========
                this.loading(true);

                const rs = await this.call({
                    Action: 'GroupSale/Save', Object: {
                        Id: this.editId,
                        Name: this.Name,
                    }
                });
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    this.showToast({ isShow: true, title: '', content: rs.Error, type: 'error' });
                } else {
                    let action = 'Save';
                    if (this.editId == 0) action = 'Add';
                    this.showToast({
                        isShow: true, title: '', content: `<span style="font-weight:bold">${action} ${this.Name}</span> <span style="font-weight: bold;color: blue;">successful!</span> `, type: 'success'
                    });
                    if (action == 'Save') {
                        for (let i = 0; i < this.data.length; i++) {
                            if (this.data[i].Id == this.editId) {
                                this.data[i].Name = this.Name;
                            }
                        }
                    }
                    else this.getAll();
                    this.showEditForm = false;
                }
            },
            async toggleDisable(itemId) {
                if (!(this.checkAcl("Edit"))) {
                    this.showToast({ isShow: true, title: '', content: `No permission!`, type: 'error' });
                    return;
                }
                this.loading(true);
                const rs = await this.call({ Action: 'GroupSale/toggleDisable', Object: { Id: itemId } })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    for (let i = 0; i < this.data.length; i++) {
                        if (this.data[i].Id == itemId) {
                            this.data[i].IsDisable = !this.data[i].IsDisable;
                            this.showToast({
                                isShow: true, title: '', content: `<span style="font-weight:bold">${this.data[i].Name}</span> is <span style="font-weight: bold;` + (!this.data[i].IsDisable ? 'color: blue;">enabled' : 'color: red;">disabled') + `</span> `, type: 'success'
                            });
                            break;
                        }
                    }
                    
                }
            },
            doTable() {
                const _this = this;
                _this.loaded = true;

                _this.dataTable = $('#datatable').DataTable(
                    {
                        processing: true,
                        responsive: true,
                    }
                );
                _this.loading(false);
            },
        },

        created() {
            
        },
        mounted() {            
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                console.log("user index newVal", newVal);
                if (newVal) {
                    this.start();
                }
            },
            Name() {
                setTimeout(this.validName, 100);
            },
        }
    };
</script>
